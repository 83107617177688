import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { showLoading, hideLoading, showToast } from "@/utils/utils"; // 导入工具函数

import { accountPage, pageapp, pageUpi } from "@/api/account";
export default defineComponent({
  name: "UPIPage",

  setup() {
    const tools = ref([]); // 存储分页查询的工具列表

    const loading = ref(false); // 加载状态

    const finished = ref(false); // 是否加载完成

    const currentPage = ref(1); // 当前页数

    const pageSize = 10; // 每页显示数量

    const router = useRouter(); // 切换账户的在线和离线状态

    const toggleOnlineStatus = async tool => {
      try {
        const newStatus = tool.onlineCode === "1" ? "0" : "1";
        tool.onlineCode = newStatus; // 切换状态

        console.log(`Updated online status to: ${newStatus} for ${tool.id}`);
      } catch (error) {
        console.error("Failed to update online status:", error);
      }
    }; // 点击 Edit 按钮时，将工具数据传递到 editAccount 页面


    const editAccount = tool => {
      router.push({
        path: "/accountList/editAccount",
        query: {
          id: tool.id,
          appCode: tool.appCode,
          appLoginId: tool.appLoginId,
          appLoginName: tool.appLoginName,
          appLoginPwd: tool.appLoginPwd,
          loginName: tool.loginName
        }
      });
    };

    const addUPI = tool => {
      router.push({
        path: "/accountList/addUPI",
        query: {
          id: tool.id,
          appCode: tool.appCode,
          appLoginId: tool.appLoginId,
          appLoginName: tool.appLoginName,
          appLoginPwd: tool.appLoginPwd,
          loginName: tool.loginName
        }
      });
    }; // 检查 UPI 账户状态


    const checkUpiStatus = async tool => {
      const upiResponse = await pageUpi({
        appId: tool.id
      });
      const validUpi = upiResponse.data.records.find(upi => upi.status === "0"); // 找到 status 为 0 的记录

      if (validUpi) {
        if (validUpi.checkCode === "1" || validUpi.checkCode === "2") {
          tool.showSwitch = true; // 显示开关

          tool.tipsMessage = ""; // 清空提示信息
        } else {
          tool.showSwitch = false; // 不显示开关

          tool.tipsMessage = "Your UPI account has not been verified yet"; // 提示验证信息
        }
      } else {
        tool.showSwitch = false; // 不显示开关

        tool.tipsMessage = "You currently do not have a UPI account"; // 提示没有UPI账户
      }
    }; // 加载工具数据


    const loadTools = async () => {
      // 确保在发起请求之前检查 loading 和 finished 状态，防止重复调用
      if (loading.value || finished.value) return;
      showLoading("Loading accounts..."); // 显示加载中的 Toast

      loading.value = true;

      try {
        const response = await accountPage({
          current: currentPage.value,
          size: pageSize
        });

        if (response.code === 1 && response.data.records.length > 0) {
          // 获取 pageapp 的数据
          const appResponse = await pageapp();
          const appRecords = appResponse.data.records; // 循环 accountPage 的记录并在其中添加所有 appRecords 的 appName 和 logoUrl

          const newTools = await Promise.all(response.data.records.map(async (tool, index) => {
            // 根据 index 顺序直接将 appRecords 的数据添加到 tool 中
            const appData = appRecords[index % appRecords.length]; // 使用取余方式循环获取 appRecords 的数据

            tool.appName = (appData === null || appData === void 0 ? void 0 : appData.appName) || ""; // 添加 appName

            tool.logoUrl = (appData === null || appData === void 0 ? void 0 : appData.logoPng) || "https://via.placeholder.com/150"; // 添加 logoUrl，未找到时使用占位图片
            // 调用 checkUpiStatus 方法检查工具的 UPI 状态

            await checkUpiStatus(tool);
            return tool;
          }));
          tools.value.push(...newTools);
          console.log(tools); // 判断是否加载完成

          if (tools.value.length >= response.data.total) {
            finished.value = true; // 数据已全部加载，设置 finished 状态
          } else {
            currentPage.value++; // 增加页码以加载更多数据
          }
        } else {
          finished.value = true; // 如果没有数据或返回错误，标记为完成
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
        finished.value = true; // 请求失败也标记为完成，防止重复调用

        showToast("Error fetching accounts");
      } finally {
        loading.value = false; // 无论请求成功与否，都应关闭加载状态

        hideLoading(); // 请求完成后关闭加载中的 Toast
      }
    }; // 刷新工具数据


    const onRefresh = async () => {
      currentPage.value = 1;
      tools.value = [];
      finished.value = false;
      await loadTools();
    }; // 加载更多工具数据


    const onLoad = async () => {
      if (!finished.value) {
        await loadTools();
      }
    };

    const toDetail = path => {
      router.push(path);
    }; // 点击 Authorize 按钮时，将工具数据传递到 mobikwik 页面


    const Authorize = tool => {
      // 构建目标路径，使用 tool.appName 拼接路径地址
      const targetPath = `/accountList/selecttool/${tool.appName}`; // 使用 router 跳转到目标路径，同时传递 appCode 和 appLoginName 参数

      router.push({
        path: targetPath,
        query: {
          appCode: tool.appCode,
          appLoginName: tool.appLoginName,
          authorize: "true" // 添加一个标识符，表明是重新授权

        }
      });
    }; // 初始化时调用接口获取工具列表


    onMounted(() => {
      loadTools();
    });
    return {
      tools,
      editAccount,
      toggleOnlineStatus,
      toDetail,
      loading,
      finished,
      onRefresh,
      onLoad,
      addUPI,
      Authorize,
      checkUpiStatus
    };
  }

});